<template>
  <div class="flex pt-10 pb-10 flex-col flex-wrap xl:flex-row items-start" :class="{ 'md:flex-row ': isMobile }">
  <div class="order-1 w-full xl:w-1/2 flex flex-col flex-wrap md:flex-row overflow-hidden" :class="{ 'xl:w-1/2 md:w-1/2 sm:w-full': isMobile }">
    <div class="relative p-4 bg-gray-900 rounded-xl mx-auto md:ml-auto md:mr-0">
      <slot name="image"></slot>
    </div>
  </div>
  <div class="order-3 w-full xl:w-1/2 flex-end" :class="{ 'xl:w-1/2 md:w-1/2 sm:w-full': isMobile }">
    <div class="relative z-10 xl:pt-0 xl:pl-4 xl:pr-4 pt-4" :class="{ 'md:pt-0 md:pl-4 md:pr-4': isMobile }">
      <div class="bg-white p-4 rounded-xl ">
      <main class="mt-2 pl-4 pr-4">
        <div class="text-left bg-white">
          <h1 class="text-3xl">
          <slot name="title"></slot>
          </h1>
          <slot name="content"></slot>
          </div>
      </main>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'PortfolioItem',
  props: ['isMobile'],

}
</script>

<style scoped>

  button, .button {
  background-color: #95C11F;
    cursor: 
    pointer;
  }
  ul {
     margin-left: 30px;
 
  }
  li {
      margin-top: 15px;
      list-style-image: url('../assets/images/leaf.png');
  }


</style>

