<template>
  <div class="flex flex-col flex-wrap md:flex-row w-full overflow-hidden">
    <div class="relative z-10 p-4 max-w-screen-2xl md:w-full mx-auto">
    <PortfolioItem>
      <template #image>
        <img class="object-scale-down" src="../assets/images/portfolio/influence_inc.png" alt="Menu screen of Influence, Inc.">
      </template>
      <template #title>
        Influence, Inc.
      </template>
      <template #content>
        <p>A simulation-strategy game about media manipulation in the attention economy.</p>
        <ul>
          <li>
            Control of a business that provides influence for hire, mostly through social media
          </li>
          <li>
            Multiple paths that are navigated through player choices, with consequences 
          </li>
          <li>
            Developed for computers (Windows and Mac) in Unity 
          </li>
        </ul>
        <p>Influence, Inc. is available for purchase on <a href="https://store.steampowered.com/app/1670700/Influence_Inc/" target="_blank">Steam</a> and <a href="https://www.humblebundle.com/store/influence-inc" target="_blank">Humble</a>.</p>
        <br>
        <div class="flex w-full justify-center">
          <a href="https://www.influenceincgame.com" target="_blank">
            <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Visit Game Website</button>
          </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem isMobile="true">
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/politician_visualization_tool.png" alt="Screenshot from Politician Visualization Tool">
      </template>
      <template #title>
        Politician Visualization Tool
      </template>
      <template #content>
        <p>A research tool designed to facilitate a study of which factors influence voters' trust in individual politicians and their sense of representation.</p>
        <ul>
          <li>Participants customize a politician who then appears in various scenes as part of a survey</li>
          <li>Integration with Qualtrics to record and reflect participant choices</li>
          <li>Custom HTML5 development using Vue.js as a framework</li>
        </ul>
        <p>Designed and developed for the University of Basel Department of Political Sciences.</p>
        <br>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/fitmi.png" alt="Game screen from Fake It To Make It, showing comments of general outrage and positive feedback">
      </template>
      <template #title>
        Fake It To Make It
      </template>
      <template #content>
      <p>An educational game about how misinformation is created, spread, and monetized.</p>            
        <ul>
          <li>
            Simulation-style gameplay, scaffolded for learning
          </li>
          <li>
            Custom HTML5 development using Ember.js as a framework
          </li>
        </ul>
        <p>Created by Curious Bird founder Amanda Warner, Fake It To Make It has been played more than 300,000 times in 170 different countries.</p>
        <br>
        <div class="flex w-full justify-center">
        <a href="https://www.fakeittomakeitgame.com/" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Open Game</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
        <img class="object-scale-down" src="../assets/images/portfolio/milos_money_front.png" alt="Opening screen of Milo's Money Game with a happy child dinosaur throwing money in the air">
      </template>
      <template #title>
        Milo's Money Game
      </template>
      <template #content>
        <p>An educational game designed to teach children ages 5-7 about spending and saving.</p>      
        <ul>
          <li>Gameplay and choices that support learning</li>
          <li>Adorable anthropomorphized dinosaurs</li>
          <li>Custom HTML5 development using Vue.js as a framework</li>
        </ul>
        <p>Created in collaboration with <a href="https://www.justfinancefoundation.org.uk/" target="_blank">Just Finance Foundation</a>.</p>
        <p>The game is part of the <a href="https://milosmoney.co.uk/" target="_blank">Milo's Money</a> teaching resource: a storybook, a game, and a set of accompanying resources. It was a finalist in the <a href="https://www.geeawards.com/" target="_blank">2021 GEE! Awards</a>.</p>
        <br>
        <div class="flex w-full justify-center">
        <a href="https://game.milosmoney.co.uk" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Open Game</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem isMobile="true">
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/demodice.png" alt="Game screen showing four dice rolling in a box">
      </template>
      <template #title>
        DemoDice
      </template>
      <template #content>
      <p>A cube-based storytelling game.</p>            
        <ul>
          <li>
            Encourages players' reflections on their experiences with democracy, as well as their desires and ideas for a more democratic future
          </li>
            <li>
            Built in Unity for iOS and Android
          </li>
        </ul>
        <p>Developed in collaboration with DemoGames, an Erasmus+ project designed to introduce young citizens to topics related to democracy through games.</p>
        <br>
        <div class="flex w-full justify-center">

        <a href="https://apps.apple.com/in/app/demogames-demodice/id1570117461?uo=2/" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center mr-3">Download for iOS</button>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.CuriousBirdAB.DEMODice" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center ml-3">Download for Android</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem isMobile="true">
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/observers.png" alt="Game screen from Observers menu">
      </template>
      <template #title>
        Observers
      </template>
      <template #content>
      <p>A riddle-based game inspired by the concept of Black Stories.</p>            
        <ul>
          <li>
            Players need to find the stories behind democracy-themed riddles, and by doing so explore topics such as justice, fairness, equality, and the rule of law
          </li>
            <li>
            Custom, mobile-optimized HTML5 development using Vue.js
          </li>
        </ul>
        <p>Developed in collaboration with DemoGames, an Erasmus+ project designed to introduce young citizens to topics related to democracy through games.</p>
        <br>
        <div class="flex w-full justify-center">
        <a href="https://observers.curiousbird.se/" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Open Game</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem isMobile="true">
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/post_facto.png" alt="Screenshot from Post Facto">
      </template>
      <template #title>
        Post Facto
      </template>
      <template #content>
      <p>An educational game where players attempt to stop a rogue robot from spreading a sketchy article.</p>            
        <ul>
          <li>Hands-on practice fact-checking and flagging suspicious items
          </li>
          <li>
            Custom HTML5 development using Ember.js as a framework
          </li>
        </ul>
        <p>Curious Bird founder Amanda Warner designed and developed this game in collaboration with journalist <a href='https://tamarwilner.wordpress.com/' target='_blank'>Tamar Wilner</a>.</p>
        <br>
        <div class="flex w-full justify-center">
        <a href="https://www.postfactogame.com/" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Open Game</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <!--div v-if="showMore" id="additional"-->
    <div id="additional">
      <h1 class="text-3xl mt-14">Additional projects by Curious Bird founder Amanda Warner</h1>
    <!--PortfolioItem>
      <template #image>
        <img class="object-scale-down" src="../assets/images/portfolio/book_of_travels.png" alt="Screenshot from the game Book of Travels">
      </template>
      <template #title>
        Book of Travels
      </template>
      <template #content>
        <p>A serene, multi-player roleplaying adventure game set in a hand-painted world that players can explore alone or with others.</p>
        <p>As part of the team creating this game at Might and Delight, Amanda's roles included:</p>
           <ul>
            <li>Designing and implementing (in Unity/C#) features and updates including vehicles, inventory, fishing, and a player journal.</li>
            <li>Providing design input on additional features including cooking, combat, death/resurrection, level randomization, an atlas system, and player onboarding</li>
            <li>Assisting with feature roadmapping and release management</li>
           </ul>
        <br>
        <div class="flex w-full justify-center">
          <a href="https://www.bookoftravels.com/" target="_blank">
            <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Visit Game Website</button>
          </a>
        </div>
      </template>
    </PortfolioItem-->
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/tb_patient_education_videos.png" alt="Screenshot from patient education video">
      </template>
      <template #title>
        Patient Education Videos
      </template>
      <template #content>
        <p>A series of patient education videos designed to introduce key messages about tuberculosis.</p>
        <p>Working with Rutgers University, Amanda assisted with the needs analysis to determine priority topics and then scripted the videos.</p>
        <br>
        <div class="flex w-full justify-center">
          <a href="https://globaltb.njms.rutgers.edu/educationalmaterials/patientedvideos.php" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Watch Videos</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/epi_change_management.png" alt="Screenshot from EPI Change Management simulation">
      </template>
      <template #title>
        EPI Change Management Simulation
      </template>
      <template #content>
        <p>A change management simulation and related learning materials designed specifically for immunization managers.</p>
        <p>Working through Bull City Learning and with the Bill & Melinda Gates Foundation, Amanda's roles on this project were:</p>
        <ul>
          <li>Conducting a needs assessment, including stakeholder and target audience member interviews</li>
          <li>Designing the change management simulation</li>
          <li>Creating a prototype of the simulation in Vue.js and testing it with target audience members</li>
          <li>Writing the simulation text and learning materials with subject matter expert input</li>
        </ul>
        <br>
        <div class="flex w-full justify-center">
          <a href="https://learn.immunizationacademy.com/en/courses" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Available in Immunization Academy</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/epi_gender.png" alt="Screenshot from Improving Service Delivery and Increasing Vaccine Demand Using a Gender Lens course">
      </template>
      <template #title>
        Improving Service Delivery and Increasing Vaccine Demand Using a Gender Lens
      </template>
      <template #content>
        <p>A course designed to give immunization professionals the opportunity to explore gender-related challenges and opportunities at two different fictional health facilities.</p>
        <p>Working through Bull City Learning and with GAVI, Amanda's roles on this project were:</p>
        <ul>
          <li>Conducting a needs assessment, including stakeholder and target audience member interviews</li>
          <li>Testing prototypes of the core learning interactions with target audience members</li>
          <li>Designing and writing the interactive course materials</li>
        </ul>
        <br>
        <div class="flex w-full justify-center">
          <a href="https://learn.immunizationacademy.com/en/courses" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Available in Immunization Academy</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/volunteer_essentials_pathway.png" alt="Screenshot from Communication is Aid scenario">
      </template>
      <template #title>
        Volunteer Essentials Pathway
      </template>
      <template #content>
      <p>A pathway to introduce the core skills and knowledge needed to volunteer in the humanitarian sector.</p> 
      <p>In collaboration with the Humanitarian Leadership Academy, Amanda's roles in this project were:</p>           
        <ul>
          <li>Redesigning and rebuilding an existing pathway in Moodle</li>
          <li>Designing new scenario-focused activities to explore the most critical skills and knowledge</li>
        </ul>
        <br>
        <div class="flex w-full justify-center">
          <a href="https://kayaconnect.org/course/info.php?id=1285" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Open Pathway</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/mlm_screenshot.png" alt="Screenshot from MLM online module">
      </template>
      <template #title>
        EPI Mid-Level Management Training
      </template>
      <template #content>
      <p>Fourteen modules providing technical information and resources to immunization managers on topics ranging from the cold chain to supportive supervision to introducing new vaccines.</p>     
      <p>As part of a larger update and redesign through Bull City Learning, in collaboration with the WHO, Amanda's roles were:</p>
      <ul>
        <li>Leading the instructional design and writing team for the online modules</li>
        <li>Writing four of the new online modules</li>
        <li>Revising three of the facilitator-led workshops</li>
      </ul> 
        <br>
        <div class="flex w-full justify-center">
        <a href="https://app.who-afro-mlm.com/en-US" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Open Learning Platform</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem isMobile="true">
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/communication_is_aid.png" alt="Screenshot from the course Communication is Aid">
      </template>
      <template #title>
        Communication is Aid
      </template>
      <template #content>
      <p>A course where learners complete a series of decision-focused challenges to practice providing disaster-affected communities with the information they need.</p>            
      <p>In collaboration with CDAC and the Humanitarian Leadership Academy, Amanda's roles were:</p>
        <ul>
          <li>Rebuilding an existing Flash course as mobile-optimized HTML5 (using the Adapt framework)
          </li>
            <li>Updating existing content and adding several new exercises
          </li>
        </ul>
        <br>
        <div class="flex w-full justify-center">
        <a href="https://kayaconnect.org/course/info.php?id=768" target="_blank">
          <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Open Course</button>
        </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/preventing_corruption.png" alt="">
      </template>
      <template #title>
        Preventing Corruption in Humanitarian Aid
      </template>
      <template #content>
      <p>A scenario-based course where learners make decisions to explore the impact of corruption within humanitarian aid response and practice strategies for detection and prevention.
      <p>Available as a self-paced online course and a facilitator-led experience.</p>
      <p>Created in collaboration with Transparency International Norway and the IFRC. Amanda's roles were learning design (online and facilitator-led) and production support.</p>
        <br>
        <div class="flex w-full justify-center">
          <a href="https://kayaconnect.org/course/info.php?id=241" target="_blank">
            <button class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center" >Open Course</button>
          </a>
        </div>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/tb_portal.jpg" alt="">
      </template>
      <template #title>
        Childhood TB Learning Portal
      </template>
      <template #content>
      <p>A combination learning portal and resource collection for healthcare workers managing TB in children. (It is no longer active.)</p>
      <p>A project with The Union in collaboration with the World Health Organization. Amanda's roles were UI/UX design and front-end development (HTML5), in collaboration with <a href='http://www.brian-underwood.codes/'>Brian Underwood.</a></p>       
    </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/childhoodtb.png" alt="">
      </template>
      <template #title>
        Childhood TB for Healthcare Workers
      </template>
      <template #content>
      <p>A six-module online course designed to give learners the opportunity to practice the skills needed to diagnosis, treat, and prevent childhood TB.</p>
      <p>Created with The Union in collaboration with the World Health Organization. Amanda's roles were learning design and course development.</p>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/childhoodmdrtb.png" alt="">
      </template>
      <template #title>
        Childhood MDR TB for Healthcare Workers
      </template>
      <template #content>
      <p>A five-module online course designed to give learners the opportunity to practice the skills needed to diagnosis, treat, and manage childhood MDR TB.</p>
      <p>Created with The Union in collaboration with the World Health Organization. Amanda's roles were project management, learning design, and course development.</p>           
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/solar_equipment.png" alt="">
      </template>
      <template #title>
        Solar-Powered Cold Chain Equipment
      </template>
      <template #content>
      <p>A course that provides strategies and practice for the decision-making needed to determine whether and what solar-powered cold chain equipment should be used to store vaccines.</p>
      <p>Created in collaboration with UNICEF, through Bull City Learning. Amanda's role was learning design.</p>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/fhi.png" alt="Screenshot from Isoniazid Preventive Therapy for the Prevention of Tuberculosis in People Living with HIV course">
      </template>
      <template #title>
        Isoniazid Preventive Therapy for the Prevention of Tuberculosis in People Living with HIV
      </template>
      <template #content>
        <p>A course designed as a scenario-based practice space where users apply WHO guidelines to assess patient histories and address common misconceptions about IPT.</p>
        <p>This course won the third eLearning Global Giveback.</p>
        <p>Created with FHI360. Amanda's roles were learning design and course development.</p>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/cpfe1.jpg" alt="Screenshot from Client Protection and Financial Education in Microfinance course">
      </template>
      <template #title>
        Client Protection and Financial Education in Microfinance
      </template>
      <template #content>
        <p>An interaction-rich course where learners' decisions influence a variety of key indictors for a fictional microfinance institution that is facing financial and political challenges.</p>
        <p>This course won first place in the individual developer category of the second eLearning Global Giveback.</p>
        <p>Created with ACCION International and The Smart Campaign. Amanda's roles were learning design and course development.</p>
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/giftinstead.png" alt="">
      </template>
      <template #title>
        Gift Instead
      </template>
      <template #content>
        <p>Gift Instead was a mobile-responsive web site that let users create, customize, and share purchase-free gift lists. Users could explore prewritten ideas for purchase-free gifts and also create their own list to share with others. (The site is retired.)</p>
        <p>Amanda's role was UI/UX design and front-end development (HTML5, using AngularJS and Bootstrap), in collaboration with <a href='http://www.brian-underwood.codes/'>Brian Underwood</a>.</p>     
      </template>
    </PortfolioItem>
    <PortfolioItem>
      <template #image>
      <img class="object-contain" src="../assets/images/portfolio/whatleft.jpg" alt="">
      </template>
      <template #title>
        What will be left then?
      </template>
      <template #content>
        <p>An interaction that shows the depletion of non-renewable resources over the life of a child born in 2011.</p>
        <p>Amanda created this interactive data visualization as part of Information is Beautiful's first visualization challenge.</p>
      </template>
    </PortfolioItem>
    </div>
    <!--div v-else>
      <p class="text-2xl text-center"><a @click="showMoreExamples()" href="#additional">Click here to show additional projects by Curious Bird founder Amanda Warner.</a></p>
      <br/>
    </div-->

    </div>
</div>
</template>

<script>

import PortfolioItem from './PortfolioItem.vue';

export default {
  name: 'Projects',
  components: {
    PortfolioItem
  },
  data() {
    return {
      showMore: false
    }
  },
  methods: {
    showMoreExamples() {
        this.showMore = true;
    }
  }

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

button, .button {
  background-color: #95C11F;
  cursor: pointer;
}

</style>
